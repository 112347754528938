<template>
  <form class="filters filters--facility" @submit.prevent="submit">
    <div class="filters__inner">
      <SidebarFilterGroup v-for="(group, index) in filters" :key="`${group.title}-${index}`" :title="group.title"
        :type="group.type" :item="group.item" :items="group.items" :selectedItem="group.selectedItem"
        :selectedItems="group.selectedItems" :selectedValue="group.selectedValue" :action="group.action"
        :expand="group.expand" :collapse="group.collapse" />
    </div>
    <div class="filters__footer">
      <button class="filters__btn filters__btn--apply btn btn-green btn-small" type="submit">
        Применить
        <img src="@/assets/images/svg/chevron-right_white.svg" alt="">
      </button>
      <button class="filters__btn" @click.prevent="handleClearClick">
        Сбросить фильтры
      </button>
    </div>
  </form>
</template>

<script lang="js">
import { mapActions, mapGetters } from 'vuex';
import SidebarFilterGroup from '@/components/sidebar/filter/SidebarFilterGroup.vue';
import entity from '@/helpers/FetchEntities';

export default {
  name: 'SidebarFilters',
  components: {
    SidebarFilterGroup
  },
  computed: {
    filters() {
      const productGroups = this.productGroups.filter(obj => this.selectedDirections.includes(obj.direction_id));

      // Вспомогательная функция для создания фильтров
      const createFilter = ({
        title, type, item, items, action, selectedItem, selectedItems, selectedValue, expand, collapse
      }) => ({
        title,
        type,
        item,
        items,
        action,
        selectedItem,
        selectedItems: [...selectedItems],
        selectedValue,
        expand,
        collapse
      });

      // Создаем фильтры для типов партнера и признаков партнера
      const directionFilterItems = (items) => items.filter(obj => this.selectedDirections.includes(obj.direction_id));

      const filters = [];

      if (this.layer === 'partners') {
        filters.push(createFilter({
          title: 'Тип партнера',
          type: 'checkbox',
          items: directionFilterItems(this.partnerTypes),
          action: this.selectPartnerType,
          selectedItems: this.selectedPartnerTypes,
          expand: true,
          collapse: false
        }));

        // Добавляем фильтры для признаков партнера
        filters.push(createFilter({
          title: 'Признак партнера',
          type: 'checkbox',
          items: directionFilterItems(this.partnerAttributes),
          action: this.selectPartnerAttribute,
          selectedItems: this.selectedPartnerAttributes,
          expand: false,
          collapse: false
        }));
      } else if (this.layer === 'objects') {
        filters.push(createFilter({
          title: 'Вид объекта',
          type: 'checkbox',
          items: this.objectTypes,
          action: this.selectObjectType,
          selectedItems: this.selectedObjectTypes,
          expand: false,
          collapse: false
        }));

        filters.push(createFilter({
          title: 'Год сдачи объекта',
          type: 'range',
          item: this.years,
          action: this.selectYears,
          selectedItem: this.selectedYears,
          selectedItems: [],
          collapse: false
        }));
      }

      // Добавляем фильтры для каждой группы продуктов
      productGroups.forEach(item => {
        filters.push(createFilter({
          title: item.name,
          type: 'checkbox',
          items: item.products,
          action: this.selectProduct,
          selectedItems: this.selectedProducts,
          expand: false,
          collapse: false
        }));
      });

      if (this.isAuthenticated) {
        filters.push(createFilter({
          title: 'Сертификация',
          type: 'radio',
          items: [
            {
              title: this.layer === 'partners' ? 'Все партнеры' : 'Все объекты',
              id: 'all',
              value: null
            },
            {
              title: 'С сертификатом',
              id: 'with',
              value: true
            },
            {
              title: 'Без сертификата',
              id: 'without',
              value: false
            }
          ],
          action: this.selectCertified,
          selectedItems: [],
          selectedValue: this.selectedCertified,
          expand: false,
          collapse: false
        }));
      }

      return filters
    },
    ...mapGetters('layer', ['layer']),
    ...mapGetters('filter', ['selectedDirections', 'years', 'selectedYears', 'partnerTypes', 'objectTypes', 'partnerAttributes', 'productGroups', 'selectedPartnerTypes', 'selectedObjectTypes', 'selectedPartnerAttributes', 'selectedProducts', 'filterValues', 'filterId', 'selectedCertified']),
    ...mapGetters('user', ['isAuthenticated'])
  },
  methods: {
    async submit() {
      const isAllFiltersEmpty = Object.values(this.filterValues).every(value => value === null);

      if (!isAllFiltersEmpty) {
        this.startLoading();
        await entity.filter();
        this.stopLoading();
      }

      this.$router.push({ path: `/${this.layer}/filter/results?c=${this.filterId}` });
    },
    handleClearClick() {
      this.startLoading();

      this.$router.push({ path: `/${this.layer}` });
      this.clearFilter();

      this.stopLoading();
    },
    ...mapActions('filter', ['clearFilter', 'selectPartnerType', 'selectObjectType', 'selectPartnerAttribute', 'selectProduct', 'selectYears', 'selectCertified']),
    ...mapActions('loader', ['startLoading', 'stopLoading'])
  }
}
</script>

<style lang="scss" scoped>
.filters {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
  overflow-y: hidden;

  &__inner {
    overflow-y: auto;
    margin-right: 5px;
  }

  &__inner>&__group:first-child {
    margin-top: 10px;
  }

  &__inner::-webkit-scrollbar {
    width: 5px;
  }

  &__inner::-webkit-scrollbar-thumb {
    background-color: black;
  }

  &__footer {
    margin-top: auto;
    padding: 20px 15px;
    border-top: 1px solid #BFBFBF;
    display: flex;
    justify-content: left;
    align-items: center;
    position: sticky;
    bottom: 0;
    background: #fff;
  }

  &__btn {
    font-size: 18px;
    line-height: 24px;
    color: #DC143C;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &--apply {
      color: #fff;
    }

    img {
      width: 8px;
      height: 12px;
      margin-left: 8px;
    }
  }
}

@media (max-width: 1023px) {
  .filters {
    height: calc(100vh - 260px);
  }
}
</style>
